@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
.Categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: max-content;
  padding-left: 5rem;
  padding-right: 5rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
}
.Categories .Header {
  font-size: xx-large;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.Categories .Subheader {
  font-size: large;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: justify;
}
.Categories .Cardscontainer {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.Categories .Cardscontainer .Card {
  display: flex;
  position: relative;
  flex: 0 0 20.333333%;
  height: 13rem;
  padding: 0;
  border-radius: 12px;
  cursor: pointer;
  background-color: #1a202c;
  content: "Book Here";
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
  margin-top: 0.9rem;
  min-width: 20rem;
  width: 50rem;
}
.Categories .Cardscontainer #Allcategories {
  background-color: #e8f6f4;
  color: #23a994;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}
.Categories .Cardscontainer #Allcategories:hover {
  background-color: #1a202c;
  color: white;
}
.Categories .Cardscontainer .Card .Cardimage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  cursor: pointer;
}
.Categories .Cardscontainer .Card .Cardtext {
  width: 100%;
  align-self: flex-start;
  text-align: start;
  font-size: medium;
  padding: 20px;
  font-weight: 400;
}
.Categories .Cardscontainer .Card .title {
  position: absolute;
  left: 0%;
  bottom: -15%;
  color: black;
  font-size: larger;
  font-weight: 500;
}
.Categories .Cardscontainer .Card:hover .Cardimage {
  display: none;
}

.Modalheader {
  text-align: center;
}
.Modalcategories {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  font-weight: bold;
}
.Modalcategories .Categoryname {
  cursor: pointer;
}
.Modalcategories .Firstletter {
  font-size: 2rem;
  font-weight: 600;
  color: #23a994;
}
@media screen and (max-width: 1280px) {
  .Categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 18rem !important;
    padding-left: 0 !important;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
    margin-left: 0 !important;
    padding-left: 7rem !important;
    width: 95% !important;
    padding-right: 0rem !important;
  }
}
@media screen and (max-width: 1440px) {
  .Categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 2 !important;
    padding-right: 0 !important;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    padding-left: 0;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
    margin-left: 0;
    padding-right: 11rem !important;
  }
}
@media screen and (max-width: 1500px) {
  .Categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
    padding-left: 5rem;
    padding-right: 5rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 50px;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
  }
  .Categories .Cardscontainer {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 20.333333%;
    height: 13rem;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
  .Categories .Cardscontainer #Allcategories {
    background-color: #e8f6f4;
    color: #23a994;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
  }
  .Categories .Cardscontainer #Allcategories:hover {
    background-color: #1a202c;
    color: white;
  }
  .Categories .Cardscontainer .Card .Cardimage {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
  }
  .Categories .Cardscontainer .Card .Cardtext {
    width: 100%;
    align-self: flex-start;
    text-align: start;
    font-size: medium;
    padding: 20px;
    font-weight: 400;
  }
  .Categories .Cardscontainer .Card .title {
    position: absolute;
    left: 0%;
    bottom: -15%;
    color: black;
    font-size: larger;
    font-weight: 500;
  }
  .Categories .Cardscontainer .Card:hover .Cardimage {
    display: none;
  }

  .Modalheader {
    text-align: center;
  }
  .Modalcategories {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;
    font-weight: bold;
  }
  .Modalcategories .Categoryname {
    cursor: pointer;
  }
  .Modalcategories .Firstletter {
    font-size: 2rem;
    font-weight: 600;
    color: #23a994;
  }
  .Categories .Cardscontainer .CardSoon {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
  .Categories .Cardscontainer .CardSoon .CardimageSoon {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    /* filter: blur(4px); */
    filter: brightness(40%);
  }
  .card-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 23px;
    font-weight: 600;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 1700px) {
  .Categories .Header {
    padding-left: 18% !important;
  }
  .Categories .Subheader {
    padding-left: 18% !important;
    padding-right: 18% !important;
  }
  .Categories .Cardscontainer {
    padding-left: 13%;
    padding-right: 13%;
  }
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 8.333333%;
    height: 12rem;
    min-width: 20rem;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1800px) {
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 25.333333%;
    height: 16rem;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1800px) {
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 25.333333%;
    height: 13rem !important;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 6rem;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
    margin-left: 6rem;
    padding-right: 25rem !important;
  }
}
@media screen and (min-width: 2000px) {
  .Categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 10rem;
    padding-right: 5rem;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 5rem;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
    margin-left: 5rem;
    padding-right: 28rem !important;
  }
  .Categories .Cardscontainer {
    padding-left: 0%;
    padding-right: 0%;
    width: 60%;
    justify-content: flex-start;
    margin-left: 0;
  }
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 18.333333%;
    height: 25vh;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 2200px) {
  .Categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 15rem;
    padding-right: 5rem;
  }
  .Categories .Header {
    font-size: xx-large;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 5rem;
  }
  .Categories .Subheader {
    font-size: large;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: justify;
    margin-left: 5rem;
    padding-right: 35rem !important;
  }
  .Categories .Cardscontainer {
    padding-left: 0%;
    padding-right: 0%;
    width: 60%;
    justify-content: flex-start;
    margin-left: 0;
  }
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 18.333333%;
    height: 25vh;
    padding: 0;
    border-radius: 12px;
    cursor: pointer;
    background-color: #1a202c;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 540px) {
  .Categories {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
  .Categories .Header {
    text-align: center !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .Categories .Subheader {
    text-align: center !important;
    padding: 0 1rem 1rem 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .Categories .Cardscontainer {
    align-items: center;
  }
  .Categories .Cardscontainer .Card {
    display: flex;
    position: relative;
    flex: 0 0 25.333333%;
    padding: 0;
    min-width: 250px;
    min-height: 150px;
    height: 150px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #23a994;
    content: "Book Here";
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
  .Categories .Cardscontainer .Card .Cardtext {
    width: 100%;
    align-self: flex-start;
    text-align: start;
    font-size: 15px;
    padding: 0.5rem;
    font-weight: bold;
  }
  .Categories .Cardscontainer .Card .title {
    position: absolute;
    left: auto;
    bottom: -25%;
    color: black;
    font-size: medium;
    font-weight: bold;
  }
  .Categories .Cardscontainer .Card .Cardimage {
    position: absolute;
    top: 0;
    width: 250px;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
  }
}

.Categories .Cardscontainer .CardSoon {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}
.Categories .Cardscontainer .CardSoon .CardimageSoon {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
  /* filter: blur(4px); */
  filter: brightness(40%);
}
.card-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 23px;
  font-weight: 600;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
