.Otp {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.Otp .Left {
  display: flex;
  flex: 0.58;
  flex-direction: column;
}

.Otp .Left .logo_container {
  margin-left: 1rem;
}
.Otp .Left .logo_container .logo {
  min-width: 13rem;
  max-width: 13rem;
  margin-top: 0.5rem;
}
.Otp .Left .Header {
  font-weight: 600;
  font-size: xx-large;
  margin-top: 5rem;
  margin-left: 7rem;
}
.Otp .Left .Subheader {
  margin-left: 7rem;
  margin-top: 1rem;
  color: #4d4d4d;
}
.Otp .Left .Pininput {
  margin-left: 7rem;
  margin-top: 3rem;
}
.Otp .Left .Pininput .pin {
  background-color: #f0f0f0 !important;
  width: 5rem !important;
  height: 5rem !important;
  margin-right: 2.5rem;
}
.Otp .Left .Submitbtn {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 3rem;
  background-color: #23a994;
  transition: all 250ms ease-in-out;
}
.Otp .Left .Submitbtn:hover {
  transform: scale(1.03);
}
.Otp .Left .reSendText {
  margin-top: 1rem;
  margin-left: 6.5rem;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.Otp .Left .reSendText .Link {
  cursor: pointer;
  margin-left: 0.3rem;
}
.Otp .Left .reSendText .Link:hover {
  text-decoration: underline;
}
.Otp .Right {
  display: flex;
  flex: 0.42;
  width: 100%;
  max-height: 100vh;
}
.Otp .Right .Imagecontainer {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(36, 169, 148, 0.4) -2.06%,
      #000000 150.42%
    ),
    url("/public/assets/Login/login_right.jpg");
  background-size: cover;
  background-position: 5%;
  display: flex;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
}
.Otp .Right .Imagecontainer .Headerscontainer {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}
.Otp .Right .Imagecontainer .Headerscontainer .Header {
  color: white;
  font-size: 2vw;
}
.Otp .Right .Imagecontainer .Headerscontainer .Subheader {
  color: white;
  font-size: 1vw;
}
@media (max-width: 1400px) {
  .Otp .Left {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Right {
    display: flex;
    flex: 0.45;
    width: 100%;
    max-height: 100vh;
  }
  .Otp .Left .Header {
    font-weight: 600;
    font-size: xx-large;
    margin-top: 6rem;
    margin-left: 4rem;
  }
  .Otp .Left .Subheader {
    margin-left: 4rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }
  .Otp .Left .Pininput {
    margin-left: 4rem;
    margin-top: 3rem;
  }
  .Otp .Left .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Left .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 1100px) {
  .Otp .Left {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Right {
    display: flex;
    flex: 0.45;
    width: 100%;
    max-height: 100vh;
  }
  .Otp .Left .Header {
    font-weight: 600;
    font-size: xx-large;
    margin-top: 6rem;
    margin-left: 2rem;
  }
  .Otp .Left .Subheader {
    margin-left: 2rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }
  .Otp .Left .Pininput {
    margin-left: 2rem;
    margin-top: 3rem;
  }
  .Otp .Left .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Left .Submitbtn {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 940px) {
  .Otp .Left {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Right {
    display: none;
  }
  .Otp .Left .Header {
    font-weight: 600;
    font-size: xx-large;
    margin-top: 6rem;
    margin-left: 4rem;
  }
  .Otp .Left .Subheader {
    margin-left: 4rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }
  .Otp .Left .Pininput {
    margin-left: 4rem;
    margin-top: 3rem;
  }
  .Otp .Left .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Left .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 560px) {
  .Otp .Left {
    display: flex;
    flex: 1;
  }
  .Otp .Right {
    display: none;
  }
  .Otp .Left .Header {
    font-size: x-large;
    align-self: center;
    margin-top: 5rem;
    text-align: center;
    justify-self: center;
    margin-left: auto;
    width: 100%;
  }
  .Otp .Left .Subheader {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin-left: auto;
    color: #4d4d4d;
    width: 100%;
  }
  .Otp .Left .Pininput {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 3rem;
  }
  .Otp .Left .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 21vw !important;
    height: 21vw !important;
    margin-right: 3vw;
  }
  .Otp .Left .Submitbtn {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
