.clientProfile {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}
.clientProfile .profileContainer {
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: max-content;
  gap: 2rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
  align-self: center;
}
.clientProfile .profileContainer .previousContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  height: max-content;
  width: 100%;
}
.clientProfile .profileContainer .previousContainer .Previous {
  display: flex;
  width: max-content;
  height: max-content;
}
.clientProfile .profileContainer .previousContainer .Previous .previousBtn {
  border-radius: 50%;
}
.clientProfile
  .profileContainer
  .previousContainer
  .Previous
  .previousBtn
  .previousIcon {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
}
.clientProfile .profileContainer .previousContainer .Header {
  font-size: x-large;
  font-weight: 700;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
}
.clientProfile .profileContainer .submitBtn {
  background-color: #23a994;
  transition: all 300ms ease-in-out;
}
.clientProfile .profileContainer .submitBtn:hover {
  transform: scale(1.03);
}
@media (max-width: 670px) {
  .clientProfile .profileContainer {
    width: 25rem;
  }
}
